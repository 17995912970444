.chat-container {
    width: 100%;
    max-width: 1200px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0 12px 40px rgba(0,0,0,0.12);
    overflow-y: hidden;
    position: relative;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.chat-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #333e48;
    color: white;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    position: relative;
    z-index: 2;
}


.chat-header::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(to right, 
        rgba(255,255,255,0) 0%,
        rgba(255,255,255,0.1) 50%,
        rgba(255,255,255,0) 100%);
}

.chat-header img {
    height: 40px;
    object-fit: contain;
    transition: transform 0.3s ease;
}

.chat-header img:hover {
    transform: scale(1.02);
}

.chat-header button {
    padding: 10px 20px;
    background-color: #222a31;
    border-radius: 12px;
    color: white;
    border: none;
    transition: all 0.2s ease;
    font-weight: 500;
    position: relative;
    overflow: hidden;
}

.chat-header button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, rgba(255,255,255,0.1), transparent);
    transform: translateX(-100%);
}

.chat-header button:hover::before {
    transform: translateX(100%);
    transition: 0.5s;
}

.chat-header button:hover {
    background-color: #f78d1e;
    color: #333;
    transform: translateY(-1px);
}

.chat-header button:disabled:hover {
    background-color: #222a31;
    color: white;
    transform: none;
}

.chat-body {
    flex-grow: 1;
    padding: 20px 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    scroll-behavior: smooth;
    background: 
        radial-gradient(circle at 100% 0%, rgba(247,141,30,0.03) 0%, transparent 25%),
        radial-gradient(circle at 0% 100%, rgba(51,62,72,0.03) 0%, transparent 25%);
    position: relative;
}

/* Chat input styles */
.chat-input {
    min-height: 80px;
    display: flex;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0 -2px 20px rgba(0,0,0,0.06);
    gap: 15px;
    position: relative;
    z-index: 2;
}

.chat-input::before {
    content: '';
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    height: 1px;
    background: linear-gradient(to right, 
        transparent,
        rgba(0,0,0,0.05) 50%,
        transparent);
}

.chat-input textarea {
    min-height: 24px;
    max-height: 150px;
    flex-grow: 1;
    padding: 12px 16px;
    border: 2px solid #e0e0e0;
    border-radius: 12px;
    font-size: 16px;
    font-family: inherit;
    resize: none;
    transition: all 0.2s ease;
    background: #f8f9fa;
}

.chat-input textarea:focus {
    outline: none;
    border-color: #f78d1e;
    background: #ffffff;
    box-shadow: 0 0 0 4px rgba(247,141,30,0.1);
}

.chat-input textarea::placeholder {
    font-style: italic;
    color: #999;
}

.chat-input button {
    padding: 12px 28px;
    background-color: #333e48;
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;
    position: relative;
    overflow: hidden;
}

.chat-input button:hover:not(:disabled) {
    background-color: #f78d1e;
    transform: translateY(-2px);
    color: #333;
}

.chat-input button::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, rgba(255,255,255,0.2), transparent);
    transform: translateX(-100%);
}

.chat-input button:hover::after {
    transform: translateX(100%);
    transition: 0.5s;
}

.chat-input button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
}

.chat-input button:disabled:hover {
    background-color: #333e48;
    color: white;
}

.intro {
    padding: 20px;
    background-color: #222a31;
    color: white;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 500;
}

.buffer {
    margin-bottom: 20px;
}

.disclaimer {
    font-size: 12px;
    color: #929292;
    text-align: center;
    margin: 10px 0;
    font-style: italic;
    opacity: 0.8;
    transition: opacity 0.2s ease;
}

.disclaimer:hover {
    opacity: 1;
}

@media (max-width: 768px) {
    .chat-container {
        border-radius: 0;
    }

    .chat-input {
        padding: 15px;
    }
    
    .preset-questions-scroll {
        -webkit-mask-image: none;
        mask-image: none;
    }
}

@keyframes pulse {
    0% { opacity: 0.4; }
    50% { opacity: 0.7; }
    100% { opacity: 0.4; }
}

.loading-indicator {
    animation: pulse 1.5s ease-in-out infinite;
}

