* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #6f767e;
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  color: white;
  background: linear-gradient(135deg, #6f767e 0%, #5c636a 100%);
}

.better-react-mathjax {
  display: contents !important;
}

.chat-message mjx-container {
  margin: 0 !important;
  padding: 0 !important;
  min-width: 0 !important;
  max-width: 100% !important;
  background: none !important;
  border: none !important;
}

.chat-message mjx-container[display="true"] {
  margin: 0.5em 0 !important;
  padding: 0 !important;
}

.chat-message mjx-container[display="false"] {
  display: inline !important;
  margin: 0 0.1em !important;
}

/* Preserve chat message styles */
.chat-message.user .message mjx-container {
  color: white !important;
}

.chat-message.bot .message mjx-container {
  color: #333 !important;
}

/* Keep text alignment */
.message {
  text-align: left !important;
}

/* Remove any unwanted MathJax margins */
.message .text-wrapper + .math-wrapper {
  margin-top: 0.5em !important;
}

.message .math-wrapper + .text-wrapper {
  margin-top: 0.5em !important;
}

/* Ensure proper math display in messages */
.chat-message .message mjx-math {
  margin: 0 !important;
  padding: 0 !important;
}