/* Message container */
.chat-message {
    margin-bottom: 20px;
    display: flex;
    position: relative;
    width: 100%;
}

.chat-message.user {
    justify-content: flex-end;
}

.chat-message.bot {
    justify-content: flex-start;
}

/* Thinking state styling */
.chat-message.bot.thinking {
    justify-content: flex-start;
}

.chat-message.bot.thinking .message {
    min-width: 100px;
    text-align: center;
}

.loading-indicator {
    min-height: 24px;
    display: inline-block;
}

/* Message styling */
.message {
    max-width: 70%;
    padding: 16px 20px;
    margin: 0 20px;
    border-radius: 16px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    color: #333;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
    line-height: 1.6;
    text-align: left;
}

/* User message specific */
.chat-message.user .message {
    background-color: #333e48;
    color: white;
    border: none;
    box-shadow: 0 2px 12px rgba(51, 62, 72, 0.15);
    margin-right: 24px;
    border-bottom-right-radius: 4px;
}

/* Bot message specific */
.chat-message.bot .message {
    background-color: #f8f9fa;
    color: #333;
    border: 1px solid #cfcfcf;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    margin-left: 24px;
    border-bottom-left-radius: 4px;
}

/* List styling */
.message ul,
.message ol {
    padding-left: 20px;
}

/* Link styling */
.message a {
    color: #0066cc;
    text-decoration: none;
}

.message a:hover {
    text-decoration: underline;
}

/* Code styling */
.message code.inline-code {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 2px 4px;
    border-radius: 4px;
    font-family: monospace;
}

.message pre.code-block {
    background-color: #f6f8fa;
    padding: 16px;
    border-radius: 8px;
    overflow-x: auto;
}

/* Display math (equations) */
.display-math {
    display: block;
    margin: 1em 0;
    overflow-x: auto;
    padding: 0.5em 0;
    font-size: 18px;
    text-align: center;
    line-height: 1.6;
}

.display-math mjx-container {
    display: block !important;
    margin: 0.5em 0 !important;
    text-align: center !important;
}

/* Inline math */
.inline-math {
    display: inline;
    font-size: 16px;
    vertical-align: middle;
    line-height: 1.6;
    margin: 0 0.1em;
}

.inline-math mjx-container {
    display: inline !important;
    margin: 0 0.1em !important;
}

/* Preserve message styles */
.chat-message.user .message .math-wrapper mjx-container {
    color: white !important;
}

.chat-message.bot .message .math-wrapper mjx-container {
    color: #333 !important;
}

/* Handle math overflow */
.math-wrapper {
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

/* Space between elements */
.text-wrapper + .display-math {
    margin-top: 1em;
}

.display-math + .text-wrapper {
    margin-top: 1em;
}

/* Consistent font size and line height across text and math */
.text-wrapper {
    font-size: 16px;
    line-height: 1.6;
}
