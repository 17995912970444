.preset-questions-container {
    position: relative;
    width: 100%;
    padding: 0 20px;
    background: transparent;
    overflow: hidden;
}

.preset-questions-scroll {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    white-space: nowrap;
    padding: 8px 0;
    width: 100%;
    -webkit-overflow-scrolling: touch;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.preset-questions-scroll::-webkit-scrollbar {
    display: none;
}

.preset-question-button {
    padding: 10px 20px;
    background-color: #f8f9fa;
    color: #666;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
    transition: all 0.2s ease;
    white-space: nowrap;
    flex-shrink: 0;
    font-weight: 500;
    position: relative;
    overflow: hidden;
}

.preset-question-button:hover {
    background-color: #333e48;
    color: white;
    border-color: #333e48;
    transform: translateY(-2px);
}

.preset-question-button::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, rgba(255,255,255,0.1), transparent);
    transform: translateX(-100%);
}

.preset-question-button:hover::after {
    transform: translateX(100%);
    transition: 0.5s;
}

/* Fade effects at the edges */
.preset-questions-container::before,
.preset-questions-container::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 60px;
    pointer-events: none;
    z-index: 1;
}

.preset-questions-container::before {
    left: 0;
    background: linear-gradient(to right, 
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%);
}

.preset-questions-container::after {
    right: 0;
    background: linear-gradient(to left, 
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%);
}

@media (max-width: 768px) {
    .preset-questions-container {
        padding: 0 10px;
    }

    .preset-questions-container::before,
    .preset-questions-container::after {
        width: 30px;
    }

    .preset-question-button {
        padding: 8px 16px;
        font-size: 13px;
    }
}